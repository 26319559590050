<template>
  <card-item>
    <h4 class="color--grey">{{ $t('company.add_user') }}</h4>
    <p class="color--grey">{{ $t('company.who_becomes_a_member') }}</p>
    <div class="create-user">
      <form id="createUserForm" @submit="createUserSubmit">
        <input v-model="form['groups']" type="hidden" placeholder="Groups"/>
        <ab-input-field
          :label="$t('forms.first_name')"
          v-model="form['name']"
          type="text"
          :error="$v.form.name.$error"
          :errorMessage="$t('forms.no_valid_data')"
        ></ab-input-field>

        <ab-input-field
          :label="$t('forms.surname')"
          v-model="form['surname']"
          type="text"
          :error="$v.form.surname.$error"
          :errorMessage="$t('forms.no_valid_data')"
        ></ab-input-field>

        <ab-input-field
          :label="$t('forms.function')"
          v-model="form['function']"
          type="text"
          :error="$v.form.function.$error"
          :errorMessage="$t('forms.no_valid_data')"
        ></ab-input-field>

        <ab-input-field
          :label="$t('forms.email')"
          v-model="form['email']"
          type="email"
          :error="$v.form.email.$error"
          :errorMessage="$t('forms.email')"
        ></ab-input-field>

        <div class="spacer--10"></div>
        <p>Add to group:</p>
        <ul id="createUserGroups">
          <li
            class="company-groups__item"
            v-for="(group, group_index) in groups"
            :key="group_index"
          >
            <input
              :id="group.code"
              name="groups"
              :value="group.code"
              type="radio"
              v-model="form.groups"
              v-on:change="addToGroup(group.code)"
            />
            <label :for="group.code">{{ group.name }}</label>
          </li>
        </ul>
        <div v-if="postError" class="alert callout">
          <p>{{ postError }}</p>
        </div>
        <p class="text-right">
          <span class="modal__close" @click="$emit('close')">{{
              $t('app.close')
            }}</span>

          <button class="modal__save" type="submit">
            {{ $t('app.create') }}
          </button>
        </p>
      </form>
    </div>
  </card-item>
</template>

<script>
import backend from '@/backend'
import { email, minLength, required } from 'vuelidate/lib/validators'

export default {
  name: 'createUserModal',
  props: {
    users: Array,
    groups: Array,
    postError: String
  },
  data () {
    return {
      form: {
        groups: []
      }
    }
  },
  methods: {
    createUserSubmit (e) {
      e.preventDefault()
      this.$v.$touch()
      if (!this.$v.$invalid) {
        backend
          .post(`${process.env.VUE_APP_URL}/users`, this.form)
          .then((response) => {
            this.userList.push(this.form)
            this.$notify({
              group: 'custom-template',
              title: false,
              position: 'bottom left',
              text: this.$t('company.user_created')
            })
            this.$emit('close')
          })
          .catch((error) => {
            const errorMessage = JSON.parse(error.response.data.message)
            const errorResult = []
            for (const key in errorMessage) {
              errorResult.push(errorMessage[key])
            }

            this.$modal.show('dialog', {
              title: '',
              text: errorResult.join('<br/>'),
              buttons: [
                {
                  title: this.$t('app.close'),
                  default: true,
                  handler: () => {
                    this.$modal.hide('dialog')
                  }
                }
              ]
            })
          })
      }
    },
    addToGroup (value) {
      this.form.groups = []
      this.form.groups.push(value)
    }
  },
  computed: {
    userList: {
      get () {
        return this.users
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  validations: {
    form: {
      name: {
        required,
        minLength: minLength(1)
      },
      surname: {
        required,
        minLength: minLength(1)
      },
      function: {
        required,
        minLength: minLength(1)
      },
      email: {
        required,
        email
      }
    }
  }
}
</script>
