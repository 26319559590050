<template>
  <div class="grid-x grid-margin-x account">
    <div class="cell auto">
      <card-item :title="$t('company.users')" icon="icon-menu-profile">
        <ul class="company-users">
          <li class="company-users__item">
            <div class="grid-x align-middle">
              <div class="cell medium-4">
                <span class="company-users__header">{{
                    $t('company.user')
                  }}</span>
              </div>
              <div class="cell auto">
                <span class="company-users__header">{{
                    $t('company.function')
                  }}</span>
              </div>
              <div class="cell medium-2">
                <span class="company-users__header">{{
                    $t('company.status')
                  }}</span>
              </div>
              <div class="cell auto">
                <span class="company-users__header">{{
                    $t('company.groups')
                  }}</span>
              </div>
            </div>
          </li>
          <transition-group name="fade" mode="out-in">
            <li
              class="company-users__item"
              v-for="(user, index) in users"
              :key="'key-' + index"
            >
              <div class="grid-x align-middle">
                <div class="cell medium-4">
                  <div class="grid-x align-middle">
                    <div class="cell shrink">
                      <span
                        v-if="user.avatar"
                        class="user-avatar"
                        :style="userAvatar(user.avatar.url)"
                      ></span>
                      <span
                        v-else
                        class="user-avatar user-avatar--empty"
                      ></span>
                    </div>
                    <div class="cell auto">
                      <span class="company-users__name" v-if="user.name"
                      >{{ user.name }} {{ user.surname }}</span
                      >
                      <span class="company-users__option">{{
                          user.email
                        }}</span>
                    </div>
                  </div>
                </div>
                <div class="cell auto">
                  <span class="company-users__option" v-if="user.function">{{
                      user.function
                    }}</span>
                </div>
                <div class="cell medium-2">
                  <span
                    class="company-users__option"
                    v-if="user.deleted_at == null"
                  >
                    <span class="company-users__status active"></span>
                    {{ $t('company.active') }}
                  </span>
                  <span class="company-users__option" v-else>
                    <span class="company-users__status inactive"></span>
                    {{ $t('company.inactive') }}
                  </span>
                </div>
                <div class="cell auto">
                  <span class="company-users__option" v-if="user.groups">
                    <span
                      class="company-users__group-name"
                      v-for="(group_code, group_index) in user.groups"
                      :key="group_index"
                    >{{ group_code.name || group_code | capitalize }}</span
                    >
                  </span>
                </div>
              </div>
              <span
                @click="removeUserButton(index, user.id)"
                class="company-users__remove"
              ><i class="icon-icon-trash"></i
              ></span>
            </li>
          </transition-group>
          <li class="company-users__item">
            <span
              @click="show('createUserModal')"
              class="modal-trigger add-user"
            ><i class="icon-plus-circle"></i>
              {{ $t('company.add_user') }}</span
            >
          </li>
        </ul>
      </card-item>
    </div>
  </div>
</template>

<script>
import backend from '@/backend'
import createUserModal from '@/views/common/components/modals/createUserModal'

export default {
  name: 'CompanyUsers',
  data () {
    return {
      users: [],
      groups: {},
      postError: '',
      form: {
        groups: []
      }
    }
  },
  created () {
    backend.get(`${process.env.VUE_APP_URL}/users`).then(response => {
      this.users = response.data
    })
    backend.get(`${process.env.VUE_APP_URL}/user_groups`).then(response => {
      const groups = response.data.filter(function (group) {
        return group.code !== 'abrnd-godfather'
      })
      this.groups = groups
    })
  },
  methods: {
    userAvatar (avatar) {
      return 'background-image:url(\'' + avatar + '\');'
    },
    removeUserButton (index, value) {
      this.$modal.show('dialog', {
        title: '',
        text: this.$t('app.remove_item'),
        buttons: [
          {
            title: this.$t('app.cancel'),
            handler: () => {
              this.$modal.hide('dialog')
            }
          },
          {
            title: this.$t('app.remove_user'),
            default: true,
            handler: () => {
              backend
                .delete(`${process.env.VUE_APP_URL}/users/${value}`)
                .then(() => {
                  this.users.splice(index, 1)
                  this.$notify({
                    group: 'custom-template',
                    title: false,
                    position: 'bottom left',
                    text: this.$t('company.user_removed')
                  })
                  this.$modal.hide('dialog')
                })
            }
          }
        ]
      })
    },
    show (index) {
      this.$modal.show(
        createUserModal,
        {
          groups: this.groups,
          users: this.users,
          postError: this.postError
        },
        {
          draggable: false,
          scrollable: false,
          height: 'auto',
          width: '600px'
        }
      )
    }
  },
  filters: {
    capitalize: function (value) {
      if (!value) return ''
      value = value.toString().replace('-', ' ')
      return value.charAt(0).toUpperCase() + value.slice(1)
    }
  }
}
</script>

<style lang="scss" scoped>
.page__inner-content {
  > div {
    + div {
      margin-top: 40px;
    }
  }
}

.alert {
  border: 1px solid #cc3e3e;
  color: #cc3e3e;

  i {
    font-size: 16px;
    margin-right: 15px;
  }
}

hr {
  margin: 0;
}
</style>
